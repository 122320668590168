

export const Staking_Data=[
    {
        APY:"1%",
        Days:"30",

    },
    {
        APY:"2.5%",
        Days:"90",

    } ,{
        APY:"6%",
        Days:"180",

    },
    {
        APY:"14%",
        Days:"365",

    }
]